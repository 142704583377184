<template>
    <div>
        <v-dialog v-model="dialog" max-width="600" persistent>
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${category.id?'Update':"Add"} category`}}
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <div class="row">
                        <div class="col-12 form-group">
                            <v-text-field outlined dense label="Title" v-model="category.title"></v-text-field>
                            <span class="float-left text-danger"
                                  v-if="$v.category.title.$error">This information is required</span>
                        </div>
                        <div class="col-12 form-group">
                            <v-select
                                    outlined dense
                                    :items="types"
                                    label="Type"
                                    item-text="title"
                                    item-value="value"
                                    v-model="category.type"
                                    @change="getParentCategories"
                            >

                            </v-select>
                            <span class="float-left text-danger" v-if="$v.category.type.$error">This information is required</span>
                        </div>
                        <div class="col-12 form-group" v-if="!category.is_parent">
                            <v-select
                                    outlined dense
                                    :items="parent_categories"
                                    item-text="title"
                                    item-value="id"
                                    v-model="category.parent_id"
                            >
                            </v-select>
                        </div>
                        <div class="col-6 form-group">
                          <v-switch v-model="category.is_parent" label="Is Parent">
                          </v-switch>

                        </div>
                        <div class="col-6 form-group">
                          <v-switch v-model="category.is_active" label="Status">
                          </v-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-right">
                            <v-btn
                                    class="cancel-btn"
                                    depressed
                                    @click="resetForm"
                            >Cancel
                            </v-btn>
                            <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                            >Save
                            </v-btn>

                        </div>
                    </div>
                </v-card-text>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import BlogCategoryService from "@/core/services/blog/category/BlogCategoryService";

    const categoryService = new BlogCategoryService();

    export default {
        validations: {
            category: {
                title: {required},
                type: {required}
            }
        },
        data() {
            return {
                dialog: false,
                isBusy: false,
                types: [
                    {value: "event", title: "Event"},
                    {value: "blog", title: "Blog"},
                    {value: "news", title: "News"},
                ],
                category: {
                    title: null,
                    type: null,
                    parent_id: null,
                    is_parent: true,
                    is_active: true
                },
                parent_categories: []
            };
        },
        mounted() {
            this.category.type = this.categoryName;
        },
        computed: {
            categoryName() {
                return this.$route.params.category;
            }
        },
        methods: {
            getParentCategories() {
                // if(this.category.is_parent && this.category.is_parent != undefined && this.category.is_parent !=null){
                //       this.category.parent_id = null;
                // }
                categoryService
                    .parentCategories(this.category.type)
                    .then(response => {
                        this.parent_categories = response.data.data;
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            },
            editCategory(category) {
                this.edit = true;
                this.category = category;
                // this.getParentCategories();
                this.dialog = true;
            },
            createOrUpdate() {
                this.$v.category.$touch();
                if (this.$v.category.$error) {
                    setTimeout(() => {
                        this.$v.category.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.udpateCategory();
                    } else {
                        this.createCategory();
                    }
                }
            },
            createCategory() {
                this.isBusy = true;
                categoryService.store(this.category).then(response => {
                    // console.log(response)
                    this.$snotify.success("Information added");
                    this.resetForm();
                }).catch(error => {
                    // console.log(error)
                    this.$snotify.error("Something Went Wrong");
                }).finally(() => {
                    this.isBusy = false;
                })

            },
            udpateCategory() {
                this.isBusy = true;
                categoryService
                    .update(this.category.id, this.category)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        //console.log(error);
                    });
            },
            openDialog(category, type) {
                // if (type && type != undefined && type != null) {
                //     this.category.type = type;
                //     this.getParentCategories();
                // }
                if (category && category.id && category.id != undefined && category.id != null) {
                    this.editCategory(category);
                }
                this.dialog = true;

            },
            hideModal() {
                this.dialog = false;

            },
            resetForm() {
                this.edit = false;
                this.category = {
                    title: null,
                    parent_id: null,
                    is_parent: true,
                    is_active: true,
                    type: null
                };
                this.$v.$reset();
                this.hideModal();
                this.$emit("refresh");
                // this.getParentCategories();
            },
            closeDialog() {
                this.dialog = false;
            }
        }
    };
</script>
